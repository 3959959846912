import { Flex, Grid, Image, Show, Text } from "@chakra-ui/react";
import {
  ClientSafeProvider,
  getProviders,
  signIn,
  useSession,
} from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { ExternalATSRemoteUserCfyiRole } from "../api-client/orval/types";
import { CandidateFYILogo } from "../assets";
import {
  useFeatureFlagsList,
  useOrgSettingsRead,
} from "../api-client/orval/endpoints";
import getConfig from "next/config";

import {
  GoogleLoginButton,
  MicrosoftLoginButton,
  OktaLoginButton,
} from "react-social-login-buttons";
import OktaDomainForm from "../components/OktaDomainForm";

const config = getConfig();

const LoginScreen = () => {
  const [isFetchingOktaDomain, setIsFetchingOktaDomain] = useState(false);
  const session: any = useSession();
  const { replace } = useRouter();
  const { data: orgSettings } = useOrgSettingsRead({
    query: {
      enabled:
        session.status === "authenticated" &&
        session?.data?.user?.cfyiRole !==
          ExternalATSRemoteUserCfyiRole.NON_APPROVED_MEMBER,
    },
  });

  const { data: flags } = useFeatureFlagsList(
    {},
    {
      query: {
        enabled: session.status === "authenticated",
      },
    }
  );
  const [googleProvider, setGoogleProvider] =
    useState<ClientSafeProvider | null>(null);
  const [oktaPorvider, setOktaProvider] =
    useState<ClientSafeProvider | null>(null);
  const [azureProvider, setAzureProvider] =
    useState<ClientSafeProvider | null>(null);

  const microsoftLoginEnabled =
    config.publicRuntimeConfig.MICROSOFT_SSO === "enabled";

  const defaultRoute =
    session?.data?.user?.cfyiRole ===
    ExternalATSRemoteUserCfyiRole.NON_APPROVED_MEMBER
      ? "/profile"
      : orgSettings?.plan?.baseAdminUrl;

  useEffect(() => {
    async function fetchProviders() {
      const providers = await getProviders();
      if (providers?.google) {
        setGoogleProvider(providers?.google);
      }
      if (providers?.["azure-ad"]) {
        setAzureProvider(providers?.["azure-ad"]);
      }
      if (providers?.okta) {
        setOktaProvider(providers?.okta);
      }
    }
    fetchProviders();
  }, []);

  useEffect(() => {
    if (session.status === "authenticated" && defaultRoute && flags) {
      if (
        session?.data?.user?.cfyiRole ===
        ExternalATSRemoteUserCfyiRole.NON_APPROVED_MEMBER
      ) {
        if (flags.interviewerCenter) {
          replace("/interview-center");
        } else {
          replace("/profile");
        }
      } else {
        const splitRoute = defaultRoute.split(".fyi/")[1];
        const cleanRoute = splitRoute === "portal" ? "portals" : splitRoute;
        replace(`/${cleanRoute}`);
      }
    }
  }, [session.status, replace, defaultRoute, flags]);

  const handleBack = () => {
    setIsFetchingOktaDomain(false);
  };

  if (session.status === "loading" || session.status === "authenticated") {
    return null;
  }

  return (
    <Grid gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }}>
      <Flex
        flexDirection={"column"}
        alignItems="center"
        justifyContent={"center"}
        h={"100vh"}
        background="palegray.500"
        gap={"16px"}
      >
        <Image src="/logo.svg" height="80px" aria-label="candidate.fyi logo" />

        <Text color={"deepBlue.500"} fontWeight={500} fontSize="28px">
          Login to your account
        </Text>
        {isFetchingOktaDomain ? (
          <OktaDomainForm onBack={handleBack} />
        ) : (
          <Flex flexDir="column" gap="4px" w="280px">
            {googleProvider && (
              <GoogleLoginButton
                onClick={() => signIn(googleProvider.id, { callbackUrl: "/" })}
              />
            )}
            {microsoftLoginEnabled && azureProvider && (
              <MicrosoftLoginButton
                onClick={() =>
                  signIn(
                    azureProvider.id,
                    {
                      callbackUrl: "/",
                    },
                    {
                      scope: "openid profile email",
                      prompt: "select_account",
                    }
                  )
                }
              />
            )}
            {oktaPorvider && (
              <OktaLoginButton
                onClick={() => {
                  setIsFetchingOktaDomain(true);
                }}
              />
            )}
          </Flex>
        )}
      </Flex>
      <Show above="md">
        <Flex
          margin={0}
          flexDirection={"column"}
          alignItems="center"
          justifyContent={"center"}
          gap={"24px"}
          background="white"
          px="32px"
        >
          <CandidateFYILogo height="207px" width="197px" />

          <Flex gap="27px" textAlign={"center"} flexDirection={"column"}>
            <Text color={"deepBlue.500"} fontWeight={500} fontSize="28px">
              The candidate experience, reimagined.
            </Text>
            <Text color={"deepBlue.500"} fontWeight={400} fontSize="18px">
              candidate.fyi is the missing candidate experience layer for talent
              teams.
            </Text>
          </Flex>
        </Flex>
      </Show>
    </Grid>
  );
};

export default LoginScreen;
